import { 
  auth,
  signInWithEmailAndPassword,
  signOut,
  googleAuthProvider, 
  facebookAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword
} from 'auth/FirebaseAuth';

const FirebaseService = {}

FirebaseService.signInEmailRequest = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password).then(user => user).catch(err => err);
}

FirebaseService.signOutRequest = async () => {
  try {
    console.log("Sign-out function called..."); // Log to ensure the function is triggered

    // Check if auth is defined
    if (!auth) {
      console.error("Firebase auth not initialized.");
      return;
    }

   await signOut(auth); // Perform sign-out
    console.log("Sign-out successful"); // If successful, this should log

    return true; // Indicate success
  } catch (err) {
    // console.error("Error during sign-out", err); // If an error occurs, log it
    return err;
  }
};


FirebaseService.signInGoogleRequest = async () =>
  await signInWithPopup(auth, googleAuthProvider).then(user => user).catch(err => err);

FirebaseService.signInFacebookRequest = async () =>
  await signInWithPopup(auth, facebookAuthProvider).then(user => user).catch(err => err);

FirebaseService.signUpEmailRequest = async (email, password) =>
	await createUserWithEmailAndPassword(auth, email, password).then(user => user).catch(err => err);	
	
export default FirebaseService