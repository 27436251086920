import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password/index')),
    }
]

export const protectedRoutes = [
    {
        key: 'change-password',
        path: `/change-password`,
        component: React.lazy(() => import('views/auth-views/authentication/change-password/index')),
    },
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
    key: 'dashboard.coursetype',
    path: `${APP_PREFIX_PATH}/dashboards/coursetype`,
    component: React.lazy(() => import('views/app-views/dashboards/default/master/coursetype')),
    }  ,
    {
        key: 'dashboard.category',
        path: `${APP_PREFIX_PATH}/dashboards/status`,
        component: React.lazy(() => import('views/app-views/dashboards/default/master/status')),
        } ,
        {
            key: 'dashboard.documents',
            path: `${APP_PREFIX_PATH}/dashboards/documents`,
            component: React.lazy(() => import('views/app-views/dashboards/default/master/documents')),
            },

            {
                key: 'dashboard.course',
                path: `${APP_PREFIX_PATH}/dashboards/course`,
                component: React.lazy(() => import('views/app-views/dashboards/default/master/course')),
                }   ,
                {
                    key: 'dashboard.partner',
                    path: `${APP_PREFIX_PATH}/dashboards/university/partner`,
                    component: React.lazy(() => import('views/app-views/dashboards/default/master/universitypartner')),
                },
                {
                    key: 'dashboard.category',
                    path: `${APP_PREFIX_PATH}/dashboards/category`,
                    component: React.lazy(() => import('views/app-views/dashboards/default/master/category')),
                    },
                {
                    key: 'dashboard.questions',
                    path: `${APP_PREFIX_PATH}/dashboards/questions`,
                    component: React.lazy(() => import('views/app-views/dashboards/default/master/question')),
                    },
                    {
                        key: 'dashboard.course',
                        path: `${APP_PREFIX_PATH}/dashboards/getstatus`,
                        component: React.lazy(() => import('views/app-views/dashboards/default/master/getstatus')),
                        },
                        {
                            key: 'dashboard.college',
                            path: `${APP_PREFIX_PATH}/dashboards/college`,
                            component: React.lazy(() => import('views/app-views/dashboards/default/college')),
                        },
                        {
                            key: 'dashboard.college-add',
                            path: `${APP_PREFIX_PATH}/dashboards/college-add`,
                            component: React.lazy(() => import('views/app-views/dashboards/default/college/collegeadd')),
                        },
                        {
                            key: 'dashboard.college-edit',
                            path: `${APP_PREFIX_PATH}/dashboards/college-edit/:id`,
                            component: React.lazy(() => import('views/app-views/dashboards/default/college/collegeedit')),
                        },
                        {
                            key: 'dashboard.college-course',
                            path: `${APP_PREFIX_PATH}/dashboards/college/course/:id`,
                            component: React.lazy(() => import('views/app-views/dashboards/default/college/course')),
                        },
                        {
                            key: 'dashboard.collegecourse-add',
                            path: `${APP_PREFIX_PATH}/dashboards/college/course-add/:id`,
                            component: React.lazy(() => import('views/app-views/dashboards/default/college/course/collegecourseadd'))
                        },
                        {
                            key: 'dashboard.collegecourse-edit',
                            path: `${APP_PREFIX_PATH}/dashboards/college/course-edit/:cid/:id`,
                            component: React.lazy(() => import('views/app-views/dashboards/default/college/course/collegecourseedit')),
                        },
                        {
                            key: 'dashboard.college.import',
                            path: `${APP_PREFIX_PATH}/dashboards/collegeimports`,
                            component: React.lazy(() => import('views/app-views/dashboards/default/college/CollegeForm/importcsv')),
                        },
                        {
                            key: 'dashboard.leads',
                            path: `${APP_PREFIX_PATH}/dashboards/student`,
                            component: React.lazy(() => import('views/app-views/dashboards/default/student')),
                        },
                        {
                            key: 'dashboard.student.create',
                            path: `${APP_PREFIX_PATH}/dashboards/student-add`,
                            component: React.lazy(() => import('views/app-views/dashboards/default/student/studentadd')),
                        },
                       
                        {
                            key: 'dashboard.student.createedit',
                            path: `${APP_PREFIX_PATH}/dashboards/student-edit/:id`,
                            component: React.lazy(() => import('views/app-views/dashboards/default/student/studentedit')),
                        },
                        {
                            key: 'dashboard.search.apply',
                            path: `${APP_PREFIX_PATH}/dashboards/search-apply`,
                            component: React.lazy(() => import('views/app-views/dashboards/default/student/components/SearchApply')),
                        },
                        {
                            key: 'dashboard.student.detail',
                            path: `${APP_PREFIX_PATH}/dashboards/student-detail/:id`,
                            component: React.lazy(() => import('views/app-views/dashboards/default/student/studentdetail')),
                        },
                        {
                            key: 'dashboard.application.detail',
                            path: `${APP_PREFIX_PATH}/dashboards/application-detail/:id`,
                            component: React.lazy(() => import('views/app-views/dashboards/default/student/components/ApplicationView')),
                        },
                        {
                            key: 'dashboard.applicationlist',
                            path: `${APP_PREFIX_PATH}/dashboards/application-list`,
                            component: React.lazy(() => import('views/app-views/dashboards/default/student/ApplicationList')),
                        },
                        {
                            key: 'dashboard.studentalllist',
                            path: `${APP_PREFIX_PATH}/dashboards/student-list`,
                            component: React.lazy(() => import('views/app-views/dashboards/default/student/StudentAllList')),
                        },
                        {
                            key: 'dashboard.studentreportlist',
                            path: `${APP_PREFIX_PATH}/dashboards/mis/student`,
                            component: React.lazy(() => import('views/app-views/dashboards/default/report/student')),
                        },
                        {
                            key: 'dashboard.studentreportlist',
                            path: `${APP_PREFIX_PATH}/dashboards/mis/application`,
                            component: React.lazy(() => import('views/app-views/dashboards/default/report/application')),
                        },
                        {
        key: 'dashboard.roles',
        path: `${APP_PREFIX_PATH}/dashboards/roles`,
        component: React.lazy(() => import('views/app-views/dashboards/default/settings/role')),
    },
    {
        key: 'dashboard.roles.permission',
        path: `${APP_PREFIX_PATH}/dashboards/roles/permission/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/default/settings/role/permission')),
    },
    {
        key: 'dashboard.permissions',
        path: `${APP_PREFIX_PATH}/dashboards/permissions`,
        component: React.lazy(() => import('views/app-views/dashboards/default/settings/permission')),
    },
    {
        key: 'dashboard.users',
        path: `${APP_PREFIX_PATH}/dashboards/users`,
        component: React.lazy(() => import('views/app-views/dashboards/default/settings/user')),
    },
    {
        key: 'dashboard.users.details',
        path: `${APP_PREFIX_PATH}/dashboards/users/details/:userId`,
        component: React.lazy(() => import('views/app-views/dashboards/default/settings/user/details')),
    },
    {
        key: 'dashboard.users.employee',
        path: `${APP_PREFIX_PATH}/dashboards/users/employee/:parent_id`,
        component: React.lazy(() => import('views/app-views/dashboards/default/settings/user/employee')),
    },
    {
        key: 'dashboard.invoice',
        path: `${APP_PREFIX_PATH}/dashboards/invoice/`,
        component: React.lazy(() => import('views/app-views/dashboards/default/invoice')),
    },
    {
        key: 'dashboard.invoice.add',
        path: `${APP_PREFIX_PATH}/dashboards/invoice/create`,
        component: React.lazy(() => import('views/app-views/dashboards/default/invoice/create')),
    },
    {
        key: 'dashboard.invoice.edit',
        path: `${APP_PREFIX_PATH}/dashboards/invoice/edit/:invoice_id`,
        component: React.lazy(() => import('views/app-views/dashboards/default/invoice/edit')),
    },
    {
        key: 'dashboard.payouts',
        path: `${APP_PREFIX_PATH}/dashboards/payouts/`,
        component: React.lazy(() => import('views/app-views/dashboards/default/payouts')),
    },
    {
        key: 'dashboard.import.csv',
        path: `${APP_PREFIX_PATH}/dashboards/payouts/import-csv`,
        component: React.lazy(() => import('views/app-views/dashboards/default/payouts/importcsv')),
    },
    {
        key: 'dashboard.payouts.edit',
        path: `${APP_PREFIX_PATH}/dashboards/payouts/edit/:payout_id`,
        component: React.lazy(() => import('views/app-views/dashboards/default/payouts/edit')),
    },
    {
        key: 'dashboard.comment',
        path: `${APP_PREFIX_PATH}/dashboards/comment`,
        component: React.lazy(() => import('views/app-views/dashboards/default/comment')),
    },
    {
        key: 'dashboard.testimonials',
        path: `${APP_PREFIX_PATH}/dashboards/testimonials`,
        component: React.lazy(() => import('views/app-views/dashboards/default/testimonials')),
    },
]